.new_footer_area {
    background: var(--footerColor);
    bottom: 0;
}


.new_footer_top {
    padding: 120px 0 270px;
    position: relative;
    overflow-x: hidden;
}

.new_footer_area .footer_bottom {
    padding-top: 5px;
    padding-bottom: 50px;
}

.footer_bottom {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    color: var(--grayishBlue);
    padding: 27px 0;
}

.new_footer_top .company_widget p {
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
    color: var(--darkGrayishBlue);
    margin-bottom: 20px;
}

.new_footer_top .company_widget .f_subscribe_two .btn_get {
    border-width: 1px;
    margin-top: 20px;
}

.btn_get_two:hover {
    background: transparent;
    color: var(--blue);
}

.btn_get:hover {
    color: #fff;
    background: var(--blue);
    border-color: var(--blue);
    -webkit-box-shadow: none;
    box-shadow: none;
}

a:hover, a:focus, .btn:hover, .btn:focus, button:hover, button:focus {
    text-decoration: none;
    outline: none;
}


.new_footer_top .f_widget.about-widget .f_list li a:hover {
    color: var(--blue);
}

.new_footer_top .f_widget.about-widget .f_list li {
    margin-bottom: 11px;
}

.f_widget.about-widget .f_list li:last-child {
    margin-bottom: 0;
}

.f_widget.about-widget .f_list li {
    margin-bottom: 15px;
}

.f_widget.about-widget .f_list {
    margin-bottom: 0;
}

.new_footer_top .f_social_icon svg {
    width: 44px;
    height: 44px;
    line-height: 43px;
    background: transparent;
    border: 1px solid var(--greyishBlue);
    font-size: 24px;
}

.f_social_icon svg {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    font-size: 14px;
    line-height: 45px;
    color: var(--darkGrayishBlue);
    display: inline-block;
    background: var(--darkGrayishBlue);
    text-align: center;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.btn_get_two {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: var(--blue);;
    border-color: var(--blue);;
    color: #fff;
}

.btn_get_two:hover {
    background: transparent;
    color: var(--blue);;
}

.new_footer_top .f_social_icon svg:hover {
    color: white;
}

.new_footer_top .f_social_icon svg + svg {
    margin-left: 4px;
}

.new_footer_top .f-title {
    margin-bottom: 30px;
    color: var(--veryDarkBlue);
}

.f_600 {
    font-weight: 600;
}

.f_size_18 {
    font-size: 18px;
}

h1, h2, h3, h4, h5, h6 {
    color: var(--darkGrayishBlue);
}

.new_footer_top .f_widget.about-widget .f_list li a {
    color: var(--darkGrayishBlue);
}


.new_footer_top .footer_bg {
    position: absolute;
    bottom: 0;
    background: url("footer_bg.png") no-repeat scroll center 0;
    width: 100%;
    height: 266px;
}

.new_footer_top .footer_bg .footer_bg_one {
    background: url("volks.gif") no-repeat center center;
    width: 330px;
    height: 105px;
    background-size: 100%;
    position: absolute;
    bottom: 0;
    left: 30%;
    -webkit-animation: myfirst 22s linear infinite;
    animation: myfirst 22s linear infinite;
}

.new_footer_top .footer_bg .footer_bg_two {
    background: url("cyclist.gif") no-repeat center center;
    width: 88px;
    height: 100px;
    background-size: 100%;
    bottom: 0;
    left: 38%;
    position: absolute;
    -webkit-animation: myfirst 30s linear infinite;
    animation: myfirst 30s linear infinite;
}


@-moz-keyframes myfirst {
    0% {
        left: -25%;
    }
    100% {
        left: 100%;
    }
}

@-webkit-keyframes myfirst {
    0% {
        left: -25%;
    }
    100% {
        left: 100%;
    }
}

@keyframes myfirst {
    0% {
        left: -25%;
    }
    100% {
        left: 100%;
    }
}

/*************footer End*****************/

