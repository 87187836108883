.contact-header {
    height: 100%;
    background-size: cover;
}

.contact-header h1:first-of-type {
    font-weight: var(--weight1);
    color: var(--veryDarkBlue);
}

.contact-header h1:last-of-type {
    color: var(--veryDarkBlue);
}

@media (max-width: 400px) {
    .contact-header h1 {
        font-size: 1.5rem;
    }
}

.contact-header .header {
    text-align: center;
    line-height: 0.8;
    margin-bottom: 50px;
    margin-top: 100px;
}

.contact-header .header p {
    margin: 0 auto;
    line-height: 2;
    color: var(--grayishBlue);
}

.box p {
    color: var(--grayishBlue);
}

.box a {
    color: var(--grayishBlue);
}

.box {
    border-radius: 5px;
    box-shadow: 0 30px 40px -20px var(--grayishBlue);
    padding: 30px;
    margin: 20px;
    height: fit-content !important;
    display: block;
    overflow-x: auto;
}

.box svg {
    float: right;
}

@media (max-width: 450px) {
    .contact-header .box {
        height: 200px;
    }
}

@media (max-width: 950px) and (min-width: 450px) {
    .contact-header .box {
        text-align: center;
        height: 180px;
    }
}

.cyan {
    border-top: 3px solid var(--cyan);
}

.red {
    border-top: 3px solid var(--red);
}

.blue {
    border-top: 3px solid var(--blue);
}

.orange {
    border-top: 3px solid var(--orange);
}

h2 {
    color: var(--veryDarkBlue);
    font-weight: var(--weight3);
}

@media (min-width: 950px) {
    .row1-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .contact-header .box-down {
        position: relative;
        top: 150px;
    }

    .contact-header .box {
        width: 50%;
    }

    .contact-header .header p {
        width: 30%;
    }
}

.contact-map {
    margin-top: 20%;
}