.product-landing {
    height: 100vh;
    background-size: cover;
}

.product-landing > [class$="-heading"] {
    color: var(--veryDarkBlue);
    text-align: center;
    position: relative;;
}

.product-landing > .main-heading {
    font-weight: bold;
    font-size: 4rem;
    top: 10%;
    padding: 3%;
}

.product-landing > .sub-heading {
    font-weight: lighter;
    font-size: 2em;
    margin-top: 2%;
}

@media (max-width: 400px) {
    .product-landing > .main-heading {
        text-align: left !important;
    }
}

@media (max-width: 1200px) {
    .product-landing > .main-heading {
        top: 0;
    }
}

.product-accessories {
    background-color: var(--offwhite);
}

.product-image {
    margin: 0 50% 0 50%;
    width: inherit;
}

.buy-button {
    background-color: var(--blue);
    border: 1px solid var(--blue);
    color: var(--veryLightGray);
    padding: 10px 20px;
    border-radius: 16px;
    margin: 2% 0 3% 47%;
}

.buy-button:hover {
    transition: all 0.3s ease-out;
    background-color: var(--veryLightGray);
    color: var(--veryDarkBlue);
}

.owl-carousel .owl-stage {
    transition-timing-function: linear !important;
}

@media (max-width: 400px) {
    .product-accessories .box {
        max-width: 90%;
    }
}

.product-accessories .box:hover {
    cursor: pointer;
    transition: all 0.2s ease-out;
    box-shadow: var(--veryDarkBlue);
    top: -4px;
    transform: scale(1.15);
    background-color: var(--veryLightGray);
}