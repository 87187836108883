.landing {
    background: url("../public/images/backgrounds/main-bg.jpg") no-repeat center center fixed;
    height: 100vh;
    background-size: cover;
}

.landing > [class$="-heading"] {
    color: var(--veryDarkBlue);
    text-align: center;
    position: relative;
}

.landing > .main-heading {
    font-weight: bold;
    font-size: 5em;
    top: 40%;
}

.landing > .sub-heading {
    font-weight: lighter;
    font-size: 2em;
    top: 43%;
}

section {
    padding: 5% 0 5% 0;
}

.statements, .testimonials {
    background-color: var(--offwhite);
}

.left-heading {
    text-align: left !important;
    font-weight: bold;
    top: 10%;
}

.product-intro {
    height: 100%;
}

.product-intro > div > .main-heading {
    color: rgba(56, 51, 51, 0.84);
    position: relative;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}