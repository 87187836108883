.about-us {
    /*background: url("../../public/images/backgrounds/why-bg.jpg") no-repeat center center fixed;*/
    background: url("../../public/images/product/product.jpg") no-repeat center center fixed;
    height: 100vh;
    background-size: cover;
}

.overlay-content {
    background: rgba(39, 62, 84, 0.82);
    height: inherit;
    margin-top: -5%;
}

.about-us .main-heading {
    font-weight: bold;
    font-size: 5em;
    top: 40%;
    position: relative;
    color: var(--veryLightGray);
}

.why-us, .recognition {
    background-color: var(--offwhite);
}

.why-us .main-heading {
    color: var(--veryDarkBlue);
    position: relative;
}

.why-us > div > div > .sub-heading > h4 {
    color: var(--veryDarkBlue);
    font-weight: normal;
    margin-right: 50%;
}

.who-are-we .team-image {
    width: 100%;
    object-fit: contain;
    min-height: 150px;
    max-height: 200px;
}

.recognition-confetti {
    z-index: 0;
    position: absolute;
    margin-left: 50%;
    margin-top: -5%;
}

@media only screen and (max-width: 600px) {
    .recognition-confetti {
        margin-left: 10%;
        margin-top: -30%;
    }
}