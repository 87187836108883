
.testimonial-container h1,
.testimonial-container h2,
.testimonial-container h3,
.testimonial-container h4,
.testimonial-container h5,
.testimonial-container h6 {
    color: #3e4555;
}

.testimonial-container .bg-light {
    background-color: #f4f8fa !important;
}

.testimonial-container .subtitle {
    color: #8d97ad;
    line-height: 24px;
}

.testimonial-container .testimonial-carousel .card-body {
    padding: 35px;
}

.testimonial-container .testimonial-carousel .thumb {
    padding: 10px 20px 10px 90px;
    margin-left: -35px;
}

.testimonial-container .testimonial-carousel .thumb .thumb-img {
    width: 60px;
    left: 20px;
    top: -10px;
}

.testimonial-container .testimonial-carousel h5 {
    line-height: 30px;
    font-size: 18px;
}

.testimonial-container .testimonial-carousel .divider {
    height: 1px;
    background: rgba(120, 130, 140, 0.13);
    width: 100px;
}

.testimonial-container .card.card-shadow {
    -webkit-box-shadow: 0 0 30px rgba(115, 128, 157, 0.1);
    box-shadow: 0 0 30px rgba(115, 128, 157, 0.1);
}

.testimonial-container .owl-theme .owl-dots .owl-dot.active span,
.testimonial-container .owl-theme .owl-dots .owl-dot:hover span {
    background: #316ce8;
}