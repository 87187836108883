:root {
    --red: hsl(0, 78%, 62%);
    --cyan: hsl(180, 62%, 55%);
    --orange: hsl(34, 97%, 64%);
    --blue: hsl(212, 86%, 64%);
    --veryDarkBlue: hsl(234, 12%, 34%);
    --grayishBlue: hsl(229, 6%, 66%);
    --darkGrayishBlue: hsl(240, 2%, 40%);
    --veryLightGray: hsl(0, 0%, 98%);
    --footerColor: hsl(0, 0%, 86%);
    --offwhite: hsl(0, 0%, 95%);
    --weight1: 200;
    --weight2: 400;
    --weight3: 600;
}

body {
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    background-color: var(--veryLightGray);
}

div {
    text-align: justify;
    text-justify: inter-word;
}
